import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import './MyAccount.css'

function MyAccount() {
  const navigate = useNavigate()
  const [credits, setCredits] = useState({ best_moves: 0, hints: 0, analyses: 0, tier: 0 })
  const [error, setError] = useState('')
  const [loggedInEmail, setLoggedInEmail] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem('token')
    const email = localStorage.getItem('email')
    if (token && email) {
      setLoggedInEmail(email)
      setIsLoggedIn(true)
    }

    const fetchCredits = async () => {
      try {
        const response = await axios.get('/api/my-account', {
          headers: { 'x-access-token': localStorage.getItem('token') }
        })
        setCredits(response.data)
      } catch (error) {
        console.error(error)
      }
    }
    fetchCredits()
  }, [])

  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('email')
    setLoggedInEmail(null)
    setIsLoggedIn(false)
    navigate('/')
  }

  const handleCancelSubscription = async () => {
    try {
      await axios.post(
        '/api/cancel-subscription',
        {},
        {
          headers: { 'x-access-token': localStorage.getItem('token') }
        }
      )
      setCredits({ ...credits, tier: 0 })
    } catch (error) {
      console.error(error)
      setError('Failed to cancel subscription')
    }
  }

  const handleChangeSubscription = () => {
    const currentTier =
      credits.tier === 1 ? 'Basic' : credits.tier === 2 ? 'Standard' : credits.tier === 3 ? 'Premium' : 'None'
    navigate(`/checkout?tier=${currentTier}`)
  }

  const handleSubscribe = () => {
    navigate('/checkout')
  }

  const handlePurchaseCredits = () => {
    navigate('/purchase-credits')
  }

  return (
    <div className="my-account-container">
      <div className="user-info">
        {loggedInEmail ? (
          <>
            <div className="dropdown">
              <button className="dropdown-toggle" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                ☰
              </button>
              {isDropdownOpen && (
                <div className="dropdown-menu">
                  <button className="dropdown-item" onClick={() => navigate('/upload')}>
                    Solo
                  </button>
                  <button className="dropdown-item" onClick={() => navigate('/create_contract')}>
                    Play a Friend
                  </button>
                  <button className="dropdown-item" onClick={handleLogout}>
                    Logout
                  </button>
                </div>
              )}
            </div>
            <p>Logged in as: {loggedInEmail}</p>
          </>
        ) : (
          <>
            <p>Not logged in, please log in to use.</p>
            <div className="auth-buttons">
              <button className="register-button" onClick={() => navigate('/register')}>
                Register
              </button>
              <button className="login-button" onClick={() => navigate('/login')}>
                Log In
              </button>
              <div className="google-signin-container">
                <div id="google-signin-button"></div>
              </div>
            </div>
          </>
        )}
      </div>
      <h1>My Account</h1>
      <div className="credits-info">
        <p>Best Moves: {credits.best_moves}</p>
        <p>Hints: {credits.hints}</p>
        <p>Analyses: {credits.analyses}</p>
        <p>
          Tier:{' '}
          {credits.tier === 1 ? 'Basic' : credits.tier === 2 ? 'Standard' : credits.tier === 3 ? 'Premium' : 'None'}
        </p>
      </div>
      <div className="account-actions">
        <div className="tier-buttons">
          {credits.tier !== 0 && (
            <div className="tier-button" onClick={handleCancelSubscription}>
              <h3>Cancel Subscription</h3>
            </div>
          )}
          <div className="tier-button" onClick={credits.tier === 0 ? handleSubscribe : handleChangeSubscription}>
            <h3>{credits.tier === 0 ? 'Subscribe' : 'Change Subscription'}</h3>
          </div>
          <div className="tier-button" onClick={handlePurchaseCredits}>
            <h3>Purchase One-Time Credits</h3>
          </div>
        </div>
      </div>
      {error && <p className="error">{error}</p>}
    </div>
  )
}

export default MyAccount
