import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

const ConfirmEmail = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const token = searchParams.get('token')
    const email = searchParams.get('email')

    if (token && email) {
      localStorage.setItem('token', token)
      localStorage.setItem('email', email)
      navigate('/upload')
    }
  }, [location, navigate])

  return <div>Confirming your email...</div>
}

export default ConfirmEmail
