import React from 'react'
import './TermsOfService.css'

function TermsOfService() {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>
      <p>
        <strong>Effective Date:</strong> 06/09/2024
      </p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing or using Chess Predict ("the Service") provided by Chess Predict ("we," "our," or "us"), you agree
        to comply with and be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, you may not
        use the Service.
      </p>

      <h2>2. Description of Service</h2>
      <p>
        Chess Predict allows users to upload screenshots of digital chess games to receive predictions on the best move
        and an analysis of that move. The Service guarantees the best move every time.
      </p>

      <h2>3. Registration</h2>
      <p>
        To use the Service, you must register and create an account using your email address. You agree to provide
        accurate and complete information during registration and to update such information to keep it accurate and
        complete.
      </p>

      <h2>4. User Conduct</h2>
      <p>You agree not to:</p>
      <ul>
        <li>Use the Service for any illegal or unauthorized purpose.</li>
        <li>Upload any content that is unlawful, offensive, defamatory, or otherwise objectionable.</li>
        <li>Interfere with or disrupt the Service or servers or networks connected to the Service.</li>
      </ul>

      <h2>5. Intellectual Property</h2>
      <p>
        All content, features, and functionality of the Service, including but not limited to the prediction algorithm
        and analysis tools, are the exclusive property of Chess Predict and are protected by copyright, trademark, and
        other intellectual property laws.
      </p>

      <h2>6. Limitation of Liability</h2>
      <p>
        Chess Predict is not responsible for users using the service for cheating purposes. Chess Predict will not be
        liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or
        revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible
        losses, resulting from:
      </p>
      <ul>
        <li>Your use of or inability to use the Service.</li>
        <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
      </ul>

      <h2>7. Disclaimer of Warranties</h2>
      <p>
        The Service is provided "as is" and "as available" without warranties of any kind, either express or implied. We
        do not guarantee that the Service will be uninterrupted or error-free.
      </p>

      <h2>8. Termination</h2>
      <p>
        We reserve the right to terminate or suspend your account and access to the Service at our sole discretion,
        without notice, for conduct that we believe violates these Terms or is harmful to other users of the Service,
        us, or third parties, or for any other reason.
      </p>

      <h2>9. Changes to the Terms</h2>
      <p>
        We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new
        Terms on the Service. Your continued use of the Service after the effective date of the revised Terms
        constitutes your acceptance of the terms.
      </p>

      <h2>10. Governing Law</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the laws of the United States, without regard
        to its conflict of law principles.
      </p>

      <h2>11. Contact Information</h2>
      <p>If you have any questions about these Terms, please contact us at support@chesspredict.com.</p>
    </div>
  )
}

export default TermsOfService
