import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Upload from './Upload'
import UploadPlayAFriend from './UploadPlayAFriend'
import Register from './Register'
import Login from './Login'
import MainPage from './MainPage'
import ConfirmEmail from './ConfirmEmail'
import CreateContract from './CreateContract'
import TermsOfService from './TermsOfService'
import PrivacyPolicy from './PrivacyPolicy'
import Checkout from './Checkout'
import MyAccount from './MyAccount'
import PurchaseCredits from './PurchaseCredits'

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/upload/:contractToken" element={<UploadPlayAFriend />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/confirm_email/:token" element={<ConfirmEmail />} />
          <Route path="/create_contract" element={<CreateContract />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/my-account" element={<MyAccount />} />
          <Route path="/purchase-credits" element={<PurchaseCredits />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
