import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import './CreateContract.css'

const CreateContract = () => {
  const navigate = useNavigate()
  const [player2Email, setPlayer2Email] = useState('')
  const [player1Uploads, setPlayer1Uploads] = useState(1)
  const [player2Uploads, setPlayer2Uploads] = useState(1)
  const [expirationTime, setExpirationTime] = useState('10 minutes')
  const [message, setMessage] = useState('')
  const [showPopup, setShowPopup] = useState(true)
  const [isFullHint, setIsFullHint] = useState(true)

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) {
      navigate('/')
    }
  }, [navigate])

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const token = localStorage.getItem('token')
      const player1Email = localStorage.getItem('email')
      const response = await axios.post(
        '/api/create_contract',
        {
          player1Email,
          player2Email,
          player1Uploads,
          player2Uploads,
          expirationTime,
          isFullHint
        },
        {
          headers: {
            'x-access-token': token
          }
        }
      )
      setMessage(response.data.message)
      const contractToken = response.data.contractToken
      navigate(`/upload/${contractToken}`)
    } catch (error) {
      setMessage(error.response.data.message)
    }
  }

  return (
    <div className="create-contract-container">
      <h2>Create Contract</h2>
      <form onSubmit={handleSubmit} className="create-contract-form">
        <label htmlFor="player1Uploads"># of Uploads for You:</label>
        <input
          type="number"
          id="player1Uploads"
          value={player1Uploads}
          onChange={e => setPlayer1Uploads(parseInt(e.target.value))}
          placeholder="Enter Uploads for You"
          min={0}
          required
        />
        <label htmlFor="player2Email">Player 2 Email:</label>
        <input
          type="email"
          id="player2Email"
          value={player2Email}
          onChange={e => setPlayer2Email(e.target.value)}
          placeholder="Enter Player 2 Email"
          required
        />
        <label htmlFor="player2Uploads"># of Uploads for Player 2:</label>
        <input
          type="number"
          id="player2Uploads"
          value={player2Uploads}
          onChange={e => setPlayer2Uploads(parseInt(e.target.value))}
          placeholder="Enter Uploads for Player 2"
          min={0}
          required
        />
        <label htmlFor="hintMode">Answer Mode:</label>
        <select
          id="hintMode"
          value={isFullHint ? 'Best Move' : 'Hint'}
          onChange={e => setIsFullHint(e.target.value === 'Best Move')}
        >
          <option value="full">Best Move</option>
          <option value="partial">Partial Hint</option>
        </select>
        <label htmlFor="expirationTime">Contract Expiry Time:</label>
        <select id="expirationTime" value={expirationTime} onChange={e => setExpirationTime(e.target.value)}>
          <option value="10 minutes">10 minutes</option>
          <option value="30 minutes">30 minutes</option>
          <option value="1 hour">1 hour</option>
          <option value="24 hours">24 hours</option>
          <option value="3 days">3 days</option>
        </select>
        <button type="submit">Create Contract</button>
      </form>
      <p className="create-contract-message">{message}</p>

      {showPopup && (
        <div className="instruction-popup" onClick={() => setShowPopup(false)}>
          <div className="instruction-content" onClick={e => e.stopPropagation()}>
            <h2>Instructions</h2>
            <p>
              Pick the number of hints you want to allow for yourself and your opponent per match, and ChessPredict will
              email them to start the game.
            </p>
            <button onClick={() => setShowPopup(false)}>Got it!</button>
          </div>
        </div>
      )}
    </div>
  )
}

export default CreateContract
