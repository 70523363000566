import React, { useState } from 'react'
import axios from 'axios'
import validator from 'validator'
import { useNavigate } from 'react-router-dom'
import './Register.css'

const Register = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')
  const [emailSentSuccessfully, setEmailSentSuccessfully] = useState(false)

  const validatePassword = password => {
    const minLength = 8
    const hasUpperCase = /[A-Z]/.test(password)
    const hasLowerCase = /[a-z]/.test(password)
    const hasNumber = /\d/.test(password)
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password)

    return password.length >= minLength && hasUpperCase && hasLowerCase && hasNumber && hasSpecialChar
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if (!validator.isEmail(email)) {
      setMessage('Invalid email format')
      return
    }
    if (!validatePassword(password)) {
      setMessage(
        'Password must be at least 8 characters long and include uppercase, lowercase, number, and special character'
      )
      return
    }
    try {
      const response = await axios.post('/api/register', { email, password })
      if (response.status === 201) {
        setMessage(response.data.message)
        setEmailSentSuccessfully(true)
      } else {
        setMessage('Error registering user')
      }
    } catch (error) {
      setMessage('Error registering user')
    }
  }

  return (
    <div className="main-page">
      <div className="form-container">
        <h2>Register</h2>
        {!emailSentSuccessfully ? (
          <form onSubmit={handleSubmit}>
            <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" required />
            <input
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
            <button type="submit">Register</button>
          </form>
        ) : (
          <p></p>
        )}
        {message && <p className="message">{message}</p>}
      </div>
    </div>
  )
}

export default Register
