import React, { useState, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import './UploadPlayAFriend.css'

function UploadPlayAFriend() {
  const navigate = useNavigate()
  const { contractToken } = useParams()
  const [instructionPopup, setInstructionPopup] = useState(true)
  const [invalidFenPopup, setInvalidFenPopup] = useState(false)
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [filePreview, setFilePreview] = useState(null)
  const [isDragging, setIsDragging] = useState(false)
  const [uploadResponse, setUploadResponse] = useState(null)
  const [analysisResponse, setAnalysisResponse] = useState(null)
  const [isWhiteMove, setIsWhiteMove] = useState(true)
  const responseRef = useRef(null)
  const [loggedInEmail, setLoggedInEmail] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [error, setError] = useState('')
  const [player1UploadsRemaining, setPlayer1UploadsRemaining] = useState(null)
  const [player2UploadsRemaining, setPlayer2UploadsRemaining] = useState(null)
  const [player1Email, setPlayer1Email] = useState('')
  const [player2Email, setPlayer2Email] = useState('')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isFullHint, setIsFullHint] = useState(true)
  const [isGettingAnalysis, setIsGettingAnalysis] = useState(false)
  const [newUpload, setNewUpload] = useState(false)
  const [audioUrl, setAudioUrl] = useState(null)

  useEffect(() => {
    const storedToken = localStorage.getItem('token')
    const email = localStorage.getItem('email')
    if (storedToken && email) {
      setLoggedInEmail(email)
      setIsLoggedIn(true)
    } else {
      initializeGoogleSignIn()
    }
    if (contractToken) {
      fetchContractDetails(contractToken)
    }

    const handlePaste = async event => {
      console.log('Paste event triggered')
      console.log('Token:', storedToken)
      console.log('isLoggedIn:', isLoggedIn)

      if (!storedToken) {
        console.log('Not logged in, navigating to login page')
        navigate(`/login?next=${encodeURIComponent(window.location.pathname)}`)
        return
      }

      setAnalysisResponse(null) // Clear previous analysis

      const items = event.clipboardData.items
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          console.log('Image found in clipboard')
          const blob = items[i].getAsFile()
          const file = new File([blob], 'pasted_image.png', { type: blob.type })
          setFile(file)
          setFileName(file.name)
          const reader = new FileReader()
          reader.onloadend = () => {
            setFilePreview(reader.result)
          }
          reader.readAsDataURL(file)
          try {
            await handleSubmit(file, isWhiteMove, isFullHint)
          } catch (error) {
            console.error('Error in handleSubmit:', error)
          }
        }
      }
    }

    document.addEventListener('paste', handlePaste)
    return () => {
      document.removeEventListener('paste', handlePaste)
    }
  }, [contractToken, navigate])

  const initializeGoogleSignIn = async () => {
    try {
      const response = await axios.get('/api/get-google-client-id-web')
      const clientId = response.data.clientId
      window.google.accounts.id.initialize({
        client_id: clientId,
        callback: handleGoogleSignIn
      })
      window.google.accounts.id.renderButton(document.getElementById('google-signin-button'), {
        theme: 'outline',
        size: 'large',
        width: 250 // Match the width of other buttons
      })
    } catch (error) {
      console.error('Error fetching Google Client ID:', error)
    }
  }

  const handleGoogleSignIn = async response => {
    if (response.credential) {
      try {
        const result = await axios.post('/api/google-signin', {
          token: response.credential
        })
        if (result.data.token) {
          localStorage.setItem('token', result.data.token)
          localStorage.setItem('email', result.data.email)
          setLoggedInEmail(result.data.email)
          setIsLoggedIn(true)
        }
      } catch (error) {
        console.error('Google Sign-In Error:', error)
      }
    } else {
      console.error('No credential received from Google Sign-In')
    }
  }

  const closeInstructionPopup = () => setInstructionPopup(false)
  const closeInvalidFenPopup = () => setInvalidFenPopup(false)

  const InstructionPopup = () => (
    <div className="instruction-popup" onClick={closeInstructionPopup}>
      <div className="instruction-content" onClick={e => e.stopPropagation()}>
        <h2>Upload Instructions</h2>
        <ol>
          <li>Drag in a screenshot of a chess game, copy and paste it, or upload it from your files.</li>
          <br /> {}
          <p>Examples of valid chess boards:</p>
          <div className="turn-images">
            <img src={require('./example_screenshot1.png')} alt="Chess.com Screenshot Example" />
            <img src={require('./example_screenshot2.png')} alt="Lichess Screenshot Example" />
          </div>
          <p>You can use a screenshot of the entire page.</p>
          <div className="example-analysis-image">
            <img src={require('./example_screenshot3.png')} alt="Full Chess.com Screenshot Example" />
          </div>
          <br /> {}
          <li>
            Select whose turn it is white or black (the player whose move it is should have started the game at the
            bottom of the board).
            <div className="turn-images">
              <img src={require('./whitetomove.png')} alt="White to Move" />
              <img src={require('./blacktomove.png')} alt="Black to Move" />
            </div>
          </li>
          <br /> {}
          <li>
            Wait for the best move, press get analysis to understand why it's the best move.
            <div className="example-analysis-image">
              <img src={require('./example_bestmove_analysis.png')} alt="Best Move and Analysis Example" />
            </div>
          </li>
        </ol>
        <p className="disclaimer">
          <strong>Disclaimer:</strong> The analysis uses a Large Language Model (LLM) and may not always be accurate or
          reliable. Please use it as a reference only and rely on your own judgment and analysis when making chess
          moves.
        </p>
        <button onClick={closeInstructionPopup}>Close</button>
      </div>
    </div>
  )

  const InvalidFenPopup = () => (
    <div className="instruction-popup" onClick={closeInvalidFenPopup}>
      <div className="instruction-content" onClick={e => e.stopPropagation()}>
        <h2>No chessboard was found in image</h2>
        <p>Please upload a screenshot like the following:</p>
        <div className="turn-images">
          <img src={require('./example_screenshot1.png')} alt="Chess.com Screenshot Example" />
          <img src={require('./example_screenshot2.png')} alt="Lichess Screenshot Example" />
        </div>
        <button onClick={closeInvalidFenPopup}>Close</button>
      </div>
    </div>
  )

  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('email')
    setLoggedInEmail(null)
    setIsLoggedIn(false)
    navigate('/')
  }

  const handleDragEnter = e => {
    e.preventDefault()
    e.stopPropagation()
    if (!isLoggedIn) {
      navigate(`/login?next=${encodeURIComponent(window.location.pathname)}`)
    } else {
      setIsDragging(true)
    }
  }

  const handleDragOver = e => {
    e.preventDefault()
    e.stopPropagation()
    if (!isDragging) setIsDragging(true)
  }

  const handleDragLeave = e => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragging(false)
  }

  const handleFileChange = async e => {
    if (!isLoggedIn) {
      navigate(`/login?next=${encodeURIComponent(window.location.pathname)}`)
    } else {
      const file = e.target.files[0]
      if (file) {
        setAnalysisResponse(null) // Clear previous analysis
        setFile(file)
        setFileName(file.name)
        if (file.type.startsWith('image/')) {
          const reader = new FileReader()
          reader.onloadend = () => {
            setFilePreview(reader.result)
          }
          reader.readAsDataURL(file)
          await handleSubmit(file, isWhiteMove, isFullHint)
        } else {
          setFilePreview(null)
        }
      }
    }
  }

  const handleDrop = async e => {
    e.preventDefault()
    e.stopPropagation()
    if (!isLoggedIn) {
      navigate(`/login?next=${encodeURIComponent(window.location.pathname)}`)
    } else {
      setIsDragging(false)
      const files = e.dataTransfer.files
      if (files && files.length > 0) {
        setAnalysisResponse(null) // Clear previous analysis
        setFile(files[0])
        setFileName(files[0].name)
        if (files[0].type.startsWith('image/')) {
          const reader = new FileReader()
          reader.onloadend = () => {
            setFilePreview(reader.result)
          }
          reader.readAsDataURL(files[0])
          await handleSubmit(files[0], isWhiteMove, isFullHint)
        } else {
          setFilePreview(null)
        }
      }
    }
  }

  const handleSubmit = async (file, whiteToMove = isWhiteMove, fullHint = isFullHint) => {
    const storedToken = localStorage.getItem('token')
    if (!storedToken || !contractToken) {
      navigate(`/login?next=${encodeURIComponent(window.location.pathname)}`)
    } else {
      try {
        const response = await axios.get('/api/check-credits', {
          headers: { 'x-access-token': localStorage.getItem('token') }
        })
        const { bestMoves, hints } = response.data
        if ((isFullHint && bestMoves <= 0) || (!isFullHint && hints <= 0)) {
          alert('You have no more credits. Please purchase more.')
          navigate('/purchase-credits')
          return
        }

        const formData = new FormData()
        formData.append('file', file)
        formData.append('isWhiteMove', whiteToMove.toString())
        formData.append('isFullHint', fullHint.toString())
        const uploadResponse = await axios.post(`/api/upload/${contractToken}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-token': localStorage.getItem('token')
          }
        })
        setUploadResponse(uploadResponse.data)
        setNewUpload(true) // Mark as new upload
        setError('')
        if (window.innerWidth <= 768 && responseRef.current) {
          responseRef.current.scrollIntoView({ behavior: 'smooth' })
        }
        fetchContractDetails(contractToken)
      } catch (error) {
        if (error.response && error.response.status === 500) {
          setInvalidFenPopup(true)
        } else {
          console.error('this is the error:', error)
          setError(error.response.data.message)
          setUploadResponse(null)
        }
      }
    }
  }

  const getAnalysis = async () => {
    if (!isLoggedIn) {
      navigate(`/login?next=${encodeURIComponent(window.location.pathname)}`)
    } else if (
      newUpload &&
      uploadResponse &&
      uploadResponse.new_fen &&
      uploadResponse.old_fen &&
      uploadResponse.best_move &&
      uploadResponse.evaluation &&
      uploadResponse.pv
    ) {
      try {
        setIsGettingAnalysis(true) // Disable the button
        const response = await axios.get('/api/check-credits', {
          headers: { 'x-access-token': localStorage.getItem('token') }
        })
        const { analyses } = response.data
        if (analyses <= 0) {
          alert('You have no more credits. Please purchase more.')
          navigate('/purchase-credits')
          setIsGettingAnalysis(false) // Enable the button
          return
        }
        const analysisResponse = await axios.post(
          '/api/analysis',
          {
            new_fen: uploadResponse.new_fen,
            old_fen: uploadResponse.old_fen,
            best_move: uploadResponse.best_move,
            evaluation: uploadResponse.evaluation,
            pv: uploadResponse.pv
          },
          {
            headers: {
              'x-access-token': localStorage.getItem('token')
            }
          }
        )
        setAnalysisResponse(analysisResponse.data)
        setNewUpload(false) // Mark as not a new upload
        setIsGettingAnalysis(false) // Enable the button
        if (window.innerWidth <= 768 && responseRef.current) {
          responseRef.current.scrollIntoView({ behavior: 'smooth' })
        }

        // Fetch the Eleven Labs API key from the backend
        const elevenLabsResponse = await axios.get('/api/get-eleven-labs-api-key', {
          headers: { 'x-access-token': localStorage.getItem('token') }
        })
        const ELEVEN_LABS_API_KEY = elevenLabsResponse.data.apiKey

        // Generate audio from the analysis text using ElevenLabs API
        const voiceId = 'QPqjfpsegJ8qVNGXdUN3' // Replace with your desired voice ID
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'xi-api-key': ELEVEN_LABS_API_KEY
          },
          body: JSON.stringify({
            text: analysisResponse.data.analysis,
            model_id: 'eleven_monolingual_v1',
            voice_settings: {
              stability: 0.5,
              similarity_boost: 0.5
            }
          })
        }

        const audioResponse = await fetch(`https://api.elevenlabs.io/v1/text-to-speech/${voiceId}`, options)
        const audioBlob = await audioResponse.blob()
        const url = URL.createObjectURL(audioBlob)
        setAudioUrl(url)

        // Play the audio
        const audioElement = new Audio(url)
        audioElement.play()
      } catch (error) {
        setIsGettingAnalysis(false) // Enable the button in case of error
        console.error(error)
      }
    }
  }

  const finishContract = async () => {
    if (!isLoggedIn || !contractToken) {
      return
    }
    try {
      await axios.post(
        '/api/finish_contract',
        { token: contractToken },
        {
          headers: {
            'x-access-token': localStorage.getItem('token')
          }
        }
      )
      navigate('/upload')
    } catch (error) {
      console.error(error)
    }
  }

  const fetchContractDetails = async contractToken => {
    try {
      const response = await axios.get(`/api/contract/${contractToken}`, {
        headers: {
          'x-access-token': localStorage.getItem('token')
        }
      })
      setPlayer1UploadsRemaining(response.data.player1UploadsRemaining)
      setPlayer2UploadsRemaining(response.data.player2UploadsRemaining)
      setPlayer1Email(response.data.player1Email)
      setPlayer2Email(response.data.player2Email)
      setIsFullHint(response.data.isFullHint)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="upload-container">
      <div className="dropdown">
        <button className="dropdown-toggle" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          ☰
        </button>
        {isDropdownOpen && (
          <div className="dropdown-menu">
            <button className="dropdown-item" onClick={() => navigate('/my-account')}>
              My Account
            </button>
            <button className="dropdown-item" onClick={() => navigate('/upload')}>
              Solo
            </button>
            <button className="dropdown-item" onClick={handleLogout}>
              Logout
            </button>
          </div>
        )}
      </div>
      <div className="user-info">
        {loggedInEmail ? (
          <>
            <p>Logged in as: {loggedInEmail}</p>
          </>
        ) : (
          <>
            <p>Not logged in, please log in to use.</p>
            <div className="auth-buttons">
              <button className="register-button" onClick={() => navigate('/register')}>
                Register
              </button>
              <button className="login-button" onClick={() => navigate('/login')}>
                Log In
              </button>
              <div className="google-signin-container">
                <div id="google-signin-button"></div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="upload-counts">
        <p>
          {player1Email}'s uploads remaining: {player1UploadsRemaining}
        </p>
        <p>
          {player2Email}'s uploads remaining: {player2UploadsRemaining}
        </p>
      </div>
      {instructionPopup && <InstructionPopup />}
      {invalidFenPopup && <InvalidFenPopup />}

      <div className="move-turn-segmented-control">
        <button
          className={`segmented-control-button ${isWhiteMove ? 'active' : ''}`}
          onClick={() => setIsWhiteMove(true)}
          type="button"
        >
          White to Move
        </button>
        <button
          className={`segmented-control-button ${!isWhiteMove ? 'active' : ''}`}
          onClick={() => setIsWhiteMove(false)}
          type="button"
        >
          Black to Move
        </button>
      </div>

      <form className="upload-form">
        <label
          className={`drag-drop-area ${isDragging ? 'dragging' : ''} ${!isLoggedIn ? 'disabled' : ''}`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <p>
            Drag and drop a file here, copy and paste, or click here to select a file.
            <input type="file" onChange={handleFileChange} style={{ display: 'none' }} disabled={!isLoggedIn} />
          </p>
          {filePreview && <img src={filePreview} alt="Preview" className="file-preview" />}
          {fileName && <p className="file-name">{fileName}</p>}
        </label>
      </form>
      {uploadResponse && (
        <>
          <div ref={responseRef} className="upload-response">
            <h3>Upload Response:</h3>
            <p>FEN: {uploadResponse.new_fen}</p>
            <p>Certainty: {uploadResponse.certainty}</p>
            <p>Best Move: {uploadResponse.best_move}</p>
            <p>
              <strong>Chessboard Image of Best Move:</strong>
              <br />
              <img src={`data:image/jpeg;base64,${uploadResponse.image}`} alt="Chessboard" />
            </p>
          </div>
          {newUpload && (
            <button
              type="button"
              onClick={getAnalysis}
              className="analysis-button action-button"
              disabled={!isLoggedIn || isGettingAnalysis}
            >
              Get Analysis
            </button>
          )}
        </>
      )}
      <button
        type="button"
        onClick={finishContract}
        className="finish-button action-button"
        disabled={!isLoggedIn || !contractToken}
      >
        Finish Contract
      </button>

      {error && <p>{error}</p>}
      {analysisResponse && (
        <div className="analysis-response">
          <h3>Analysis:</h3>
          <p>{analysisResponse.analysis}</p>
          <br /> {}
          <p>Line to follow: {uploadResponse.pv.join(' ')}</p>
          {audioUrl && (
            <audio controls src={audioUrl}>
              Your browser does not support the audio element.
            </audio>
          )}
        </div>
      )}
    </div>
  )
}

export default UploadPlayAFriend
