import React, { useState, useEffect, useRef, useCallback } from 'react'
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'
import './Login.css'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [message, setMessage] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const nextUrlRef = useRef('/upload')

  const handleGoogleSignIn = useCallback(
    async response => {
      if (response.credential) {
        try {
          const result = await axios.post('/api/google-signin', {
            token: response.credential
          })
          if (result.data.token) {
            localStorage.setItem('token', result.data.token)
            localStorage.setItem('email', result.data.email)
            console.log('Navigating to:', nextUrlRef.current) // Debugging line
            navigate(nextUrlRef.current)
          }
        } catch (error) {
          if (error.response) {
            console.error('Error response:', error.response.data)
          }
        }
      } else {
        console.error('No credential received from Google Sign-In')
      }
    },
    [navigate]
  )

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const next = params.get('next')
    console.log('URL params:', params.toString()) // Debugging line
    console.log('Next parameter:', next) // Debugging line
    if (next) {
      nextUrlRef.current = next
    }

    // Initialize Google Sign-In
    window.google.accounts.id.initialize({
      client_id: '741998243579-aadapcsbfjlbop3qs6k84ieilo9fpc9n.apps.googleusercontent.com',
      callback: handleGoogleSignIn
    })
    window.google.accounts.id.renderButton(document.getElementById('google-signin-button'), {
      theme: 'outline',
      size: 'large'
    })
  }, [location.search, handleGoogleSignIn])

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const response = await axios.post(
        '/api/login',
        { email, password },
        {
          headers: { 'Content-Type': 'application/json' }
        }
      )
      if (response.data.token) {
        document.cookie = `token=${response.data.token}; path=/`
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('email', email)
        console.log('Navigating to:', nextUrlRef.current) // Debugging line
        navigate(nextUrlRef.current)
      } else {
        setMessage(response.data.message)
      }
    } catch (error) {
      setMessage('Login failed. Check your email and password.')
    }
  }

  return (
    <div className="main-page">
      <div className="form-container">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" required />
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="Password"
            required
          />
          <button type="submit">Login</button>
        </form>
        <p>{message}</p>
        <div id="google-signin-button"></div>
      </div>
    </div>
  )
}

export default Login
