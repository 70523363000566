import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import axios from 'axios'
import './Checkout.css'

const tiers = [
  { name: 'Basic', price: 0.0, bestMoves: 15, hints: 20, analyses: 10 },
  { name: 'Standard', price: 0.0, bestMoves: 30, hints: 45, analyses: 20 },
  { name: 'Premium', price: 0.0, bestMoves: 60, hints: 95, analyses: 40 }
]

const CheckoutForm = ({ tier, currentTier, onTierChange }) => {
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()

  const handleSubmit = async event => {
    event.preventDefault()

    // Get selected tier details
    const selectedTier = tiers.find(t => t.name === tier)
    if (!selectedTier) return

    const token = localStorage.getItem('token')

    if (selectedTier.price === 0.0) {
      // If the tier is free, directly call the backend without Stripe payment
      try {
        const response = await axios.post(
          '/api/checkout',
          { token: null, tier },
          { headers: { 'x-access-token': token } }
        )
        if (response.data.success) {
          alert('Subscribed successfully to the free tier')
          navigate(`/upload?token=${encodeURIComponent(token)}`)
        } else {
          alert('Subscription failed: ' + response.data.error)
        }
      } catch (error) {
        console.error('Subscription Error:', error)
        alert('Subscription failed: ' + (error.response?.data?.error || error.message))
      }
      return
    }

    // Proceed with Stripe payment if tier has a price > 0
    if (!stripe || !elements) {
      return
    }

    const cardElement = elements.getElement(CardElement)
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement
    })

    if (error) {
      console.error(error)
      return
    }

    try {
      const response = await axios.post(
        '/api/checkout',
        { token: paymentMethod.id, tier },
        { headers: { 'x-access-token': token } }
      )
      if (response.data.success) {
        alert('Subscribed successfully')
        navigate(`/upload?token=${encodeURIComponent(token)}`)
      } else {
        alert('Payment failed: ' + response.data.error)
      }
    } catch (error) {
      console.error('Payment Error:', error)
      alert('Payment failed: ' + (error.response?.data?.error || error.message))
    }
  }

  return (
    <form onSubmit={handleSubmit} className="checkout-form">
      <div className="tier-selector">
        <label htmlFor="tier-select">Select Tier:</label>
        <select id="tier-select" value={tier} onChange={e => onTierChange(e.target.value)}>
          {tiers.map(t => (
            <option key={t.name} value={t.name}>
              {t.name} - ${t.price.toFixed(2)}/month
            </option>
          ))}
        </select>
      </div>

      {tiers.find(t => t.name === tier)?.price > 0 && <CardElement />}

      <button type="submit" disabled={!stripe && tiers.find(t => t.name === tier)?.price > 0}>
        {tier.toLowerCase() === currentTier?.toLowerCase() ? 'Already Subscribed' : 'Pay'}
      </button>
    </form>
  )
}

const Checkout = () => {
  const [searchParams] = useSearchParams()
  const [tier, setTier] = useState('')
  const [currentTier, setCurrentTier] = useState('')
  const [stripePromise, setStripePromise] = useState(null)

  useEffect(() => {
    const fetchStripePublicKey = async () => {
      try {
        let token = localStorage.getItem('token')

        const extensionToken = searchParams.get('extension_token')

        if (extensionToken) {
          token = extensionToken
          localStorage.setItem('token', extensionToken)
        }

        if (!token) {
          console.error('No token found')
          return
        }

        const response = await axios.get('/api/get-stripe-public-key', {
          headers: {
            'x-access-token': token
          }
        })
        const stripePublicKey = response.data.publicKey
        setStripePromise(loadStripe(stripePublicKey))
      } catch (error) {
        console.error('Error fetching Stripe public key:', error)
      }
    }

    fetchStripePublicKey()

    const selectedTier = searchParams.get('tier')
    setTier(selectedTier || 'Basic')

    const fetchCurrentTier = async () => {
      try {
        let token = localStorage.getItem('token')
        const extensionToken = searchParams.get('extension_token')

        if (extensionToken) {
          token = extensionToken
          localStorage.setItem('token', extensionToken)
        }

        if (!token) {
          console.error('No token found')
          return
        }

        const response = await axios.get('/api/user-tier', {
          headers: {
            'x-access-token': token
          }
        })
        const dbTier = response.data.tier
        console.log('Tier from database:', dbTier)
        setCurrentTier(dbTier)
      } catch (error) {
        console.error('Error fetching user tier:', error)
      }
    }
    fetchCurrentTier()
  }, [searchParams])

  const handleTierChange = newTier => {
    setTier(newTier)
  }

  return (
    <div className="checkout-page">
      <h1>Checkout</h1>
      {currentTier && <p>Current Tier: {currentTier}</p>}
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <CheckoutForm tier={tier} currentTier={currentTier} onTierChange={handleTierChange} />
        </Elements>
      )}
    </div>
  )
}

export default Checkout
