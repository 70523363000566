import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { Camera, Shield, Puzzle, Chrome, Brain } from 'lucide-react'
import './MainPage.css'
import funChessImage from './fun_chess_image.jpeg'

function MainPage() {
  const navigate = useNavigate()

  useEffect(() => {
    const fetchGoogleClientId = async () => {
      try {
        const response = await axios.get('/api/get-google-client-id-web')
        const clientId = response.data.clientId
        window.google.accounts.id.initialize({
          client_id: clientId,
          callback: handleGoogleSignIn
        })
        window.google.accounts.id.renderButton(document.getElementById('google-signin-button'), {
          theme: 'outline',
          size: 'large',
          width: 250
        })
      } catch (error) {
        console.error('Error fetching Google Client ID:', error)
      }
    }

    const token = localStorage.getItem('token')
    if (token) {
      const decoded = jwtDecode(token)
      if (decoded.is_new_user) {
        alert('Welcome! You have 100 free best moves, 100 free hints, and 100 free analyses.')
      }
      navigate('/upload')
    } else {
      fetchGoogleClientId()
    }
  }, [navigate])

  const handleGoogleSignIn = async response => {
    if (response.credential) {
      try {
        const result = await axios.post('/api/google-signin', {
          token: response.credential
        })
        if (result.data.token) {
          const decoded = jwtDecode(result.data.token)
          if (decoded.is_new_user) {
            alert('Welcome! You have 5 free best moves, 7 free hints, and 3 free analysis.')
          }
          localStorage.setItem('token', result.data.token)
          localStorage.setItem('email', result.data.email)
          navigate('/upload')
        }
      } catch (error) {
        if (error.response) {
          console.error('Error response:', error.response.data)
        }
      }
    } else {
      console.error('No credential received from Google Sign-In')
    }
  }

  const handleTierSelection = tier => {
    const token = localStorage.getItem('token')
    if (!token) {
      navigate(`/login?next=/checkout?tier=${tier}`)
    } else {
      navigate(`/checkout?tier=${tier}`)
    }
  }

  const tiers = [
    { name: 'Basic', price: 0.0, bestMoves: 15, hints: 20, analyses: 10, savings: '100%' },
    { name: 'Standard', price: 0.0, bestMoves: 30, hints: 45, analyses: 20, savings: '100%' },
    { name: 'Premium', price: 0.0, bestMoves: 60, hints: 95, analyses: 40, savings: '100%' }
  ]

  return (
    <div className="main-page">
      <h1>Welcome to Chess Predict!</h1>
      <h1>Best Move, Every Time.</h1>

      {/* Features Overview Section */}
      <div className="features-container">
        <div className="features-grid">
          <div className="feature-card">
            <Camera className="feature-icon" size={48} />
            <h3>Instant Analysis</h3>
            <p>
              Upload a screenshot from any digital chess game and get immediate position analysis. Works with Chess.com,
              Lichess, and more!
            </p>
          </div>
          <div className="feature-card">
            <Puzzle className="feature-icon" size={48} />
            <h3>Learning Tool</h3>
            <p>
              Get best moves, hints, and detailed analysis to understand the critical moments in your games. Perfect for
              players of all levels.
            </p>
          </div>
          <div className="feature-card">
            <a
              href="https://chromewebstore.google.com/detail/chess-predict-extension/bcjbjjnimehokcekjaojnbmgmelfhiad"
              target="_blank"
              rel="noopener noreferrer"
              className="chrome-extension-link"
            >
              <h3>Chrome Extension</h3>
              <p>
                Get instant analysis right in your browser! Our Chrome extension lets you analyze positions with a
                single click. Available now on the Chrome Web Store!
              </p>
            </a>
          </div>
        </div>

        <div className="coming-soon">
          <h3>Coming Soon</h3>
          <div className="future-features">
            <div className="future-feature">
              <Shield className="feature-icon" size={32} />
              <p>Advanced Cheating Detection</p>
            </div>
            <div className="future-feature">
              <Brain className="feature-icon" size={32} />
              <p>Advanced Game Analysis</p>
            </div>
          </div>
        </div>
      </div>

      {/* Login/Register Section */}
      <div className="button-container">
        <p className="login-options">Register and Login, or use Google Sign-In</p>
        <button className="register-button" onClick={() => navigate('/register')}>
          Register
        </button>
        <button className="login-button" onClick={() => navigate('/login')}>
          Login
        </button>
        <p>OR</p>
        <div className="google-signin-container">
          <div id="google-signin-button"></div>
        </div>
      </div>

      {/* Subscription Tiers Section */}
      <div className="tier-container">
        <h2>Choose a Subscription Tier:</h2>
        <div className="tier-buttons">
          {tiers.map(tier => (
            <button key={tier.name} className="tier-button" onClick={() => handleTierSelection(tier.name)}>
              <h3>{tier.name}</h3>
              <p className="price">${tier.price.toFixed(2)}/month</p>
              <ul>
                <li>{tier.bestMoves} Best Moves</li>
                <li>{tier.hints} Hints</li>
                <li>{tier.analyses} Analyses</li>
                <li>
                  <i>{tier.savings} savings over one-time purchase.</i>
                </li>
              </ul>
            </button>
          ))}
        </div>
      </div>

      {/* Chess Image Section */}
      <div className="chess-image">
        <img src={funChessImage} alt="Fun Chess Image" />
      </div>

      {/* Footer Section */}
      <div className="contact-container">
        <p>
          Questions?{' '}
          <a href="mailto:support@chesspredict.com" className="support-link">
            Contact support@chesspredict.com
          </a>
        </p>
      </div>
      <div className="links-container">
        <a href="/terms-of-service" className="terms-link">
          Terms of Service
        </a>
        <span> | </span>
        <a href="/privacy-policy" className="privacy-link">
          Privacy Policy
        </a>
      </div>
    </div>
  )
}

export default MainPage
