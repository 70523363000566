import React from 'react'
import './PrivacyPolicy.css'

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>
        <strong>Effective Date:</strong> 06/29/2024
      </p>

      <h2>1. Introduction</h2>
      <p>
        Chess Predict ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how
        we collect, use, disclose, and safeguard your information when you use our Service.
      </p>

      <h2>2. Information We Collect</h2>
      <ul>
        <li>
          <strong>Personal Information:</strong> When you register for an account, we collect your email address.
        </li>
        <li>
          <strong>Usage Data:</strong> We may collect information about your interactions with the Service, such as the
          pages you visit, the time and date of your visits, and other diagnostic data.
        </li>
      </ul>

      <h2>3. Use of Information</h2>
      <p>We use the information we collect to:</p>
      <ul>
        <li>Provide, operate, and maintain the Service.</li>
        <li>Improve, personalize, and expand our Service.</li>
        <li>Communicate with you, including sending you updates and other information.</li>
        <li>Monitor and analyze usage and trends to improve your experience with the Service.</li>
      </ul>

      <h2>4. Disclosure of Information</h2>
      <p>We may share your information with:</p>
      <ul>
        <li>
          <strong>Service Providers:</strong> Third parties that perform services on our behalf, such as email services.
        </li>
        <li>
          <strong>Legal Requirements:</strong> If required by law or in response to valid requests by public
          authorities.
        </li>
      </ul>

      <h2>5. Data Security</h2>
      <p>
        We use administrative, technical, and physical security measures to protect your personal information. While we
        have taken reasonable steps to secure the personal information you provide to us, please be aware that despite
        our efforts, no security measures are perfect or impenetrable.
      </p>

      <h2>6. Your Data Protection Rights</h2>
      <p>Depending on your location, you may have the following rights regarding your personal data:</p>
      <ul>
        <li>
          <strong>The right to access</strong> – You have the right to request copies of your personal data.
        </li>
        <li>
          <strong>The right to rectification</strong> – You have the right to request that we correct any information
          you believe is inaccurate.
        </li>
        <li>
          <strong>The right to erasure</strong> – You have the right to request that we erase your personal data, under
          certain conditions.
        </li>
        <li>
          <strong>The right to restrict processing</strong> – You have the right to request that we restrict the
          processing of your personal data, under certain conditions.
        </li>
        <li>
          <strong>The right to object to processing</strong> – You have the right to object to our processing of your
          personal data, under certain conditions.
        </li>
      </ul>
    </div>
  )
}

export default PrivacyPolicy
